<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header class="pb-1">
      <h4 class="card-title font-weight-bolder">
        {{ $t('filters') }}
      </h4>
      <div class="d-flex align-items-center justify-content-end">
        <!-- Search -->
        <b-button
          variant="warning"
          class="mr-1"
          :disabled="emptyFilter || !canAccess('agency.searchAgency')"
          @click="$emit('fetch-data')"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('search') }}
          </span>
        </b-button>

        <!-- Clear -->
        <b-button
          variant="danger"
          :disabled="emptyFilter || !canAccess('agency.searchAgency')"
          @click="$emit('reset')"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="XOctagonIcon"
              size="16"
            />
            {{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </b-card-header>

    <b-card-body class="py-0">
      <b-row class="mb-2">
        <!-- STATUS ACTIVATED -->
        <b-col
          cols="12"
          md="4"
        >
          <label class="d-block">{{ $t('agency.activated') }}</label>
          <v-select
            class="w-100"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusActiveOptions"
            label="label"
            :clearable="false"
            :value="statusActiveFilter"
            :placeholder="$t('placeholderSelect')"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusActiveFilter', val)"
          >
            <template #option="data">
              <span>
                {{ $te(data.label) ? $t(data.label) : '' }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ $te(data.label) ? $t(data.label) : '' }}
              </span>
            </template>

            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-col>

        <!-- STATUS AGENCY -->
        <b-col
          cols="12"
          md="4"
        >
          <label class="d-block">{{ $t('agency.status') }}</label>
          <v-select
            class="w-100"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusAgencyOptions"
            label="label"
            :clearable="false"
            :value="statusAgencyFilter"
            :placeholder="$t('placeholderSelect')"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusAgencyFilter', val)"
          >
            <template #option="data">
              <span>
                {{ $t(data.label) }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ $t(data.label) }}
              </span>
            </template>

            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-col>

        <!-- AGENCY MANAGER -->
        <b-col
          cols="12"
          md="4"
        >
          <label class="d-block">{{ $t('agency.manager') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="agenciesManagerList"
            :reduce="(val) => val.id"
            :clearable="false"
            :value="agencyManagerFilter"
            searchable
            label="id"
            :filterable="false"
            :loading="loading"
            :placeholder="$t('placeholderSelect')"
            @open="openAgenciesManager"
            @search="searchAgenciesManager"
            @input="(val) => $emit('update:agencyManagerFilter', val)"
          >
            <template #selected-option="{firstName, lastName}">
              <div class="d-flex align-items-center">
                <span
                  class="d-block text-nowrap"
                >
                  {{ lastName }} {{ firstName }}
                </span>
              </div>
            </template>
            <template v-slot:option="{firstName, lastName}">
              <div class="d-flex align-items-center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ lastName }} {{ firstName }}
                </span>
              </div>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import useAgenciesHandle from '../useAgenciesHandle'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    statusAgencyFilter: {
      type: [String, null],
      default: null,
    },
    agencyManagerFilter: {
      type: Number,
      default: null,
    },
    statusActiveFilter: {
      type: [Boolean, null],
      default: null,
    },
    statusAgencyOptions: {
      type: Array,
      default: () => [],
    },
    statusActiveOptions: {
      type: Array,
      default: () => [],
    },
    emptyFilter: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    const {
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,
    } = useAgenciesHandle()

    return {
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.w-10{
  width: 10%;
}

.w-15{
  width: 15%;
}
</style>

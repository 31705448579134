var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "pb-1"
  }, [_c('h4', {
    staticClass: "card-title font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('filters')) + " ")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "warning",
      "disabled": _vm.emptyFilter || !_vm.canAccess('agency.searchAgency')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('fetch-data');
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "SearchIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)]), _c('b-button', {
    attrs: {
      "variant": "danger",
      "disabled": _vm.emptyFilter || !_vm.canAccess('agency.searchAgency')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('reset');
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XOctagonIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('clear')) + " ")], 1)])], 1)]), _c('b-card-body', {
    staticClass: "py-0"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('label', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t('agency.activated')))]), _c('v-select', {
    staticClass: "w-100",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.statusActiveOptions,
      "label": "label",
      "clearable": false,
      "value": _vm.statusActiveFilter,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:statusActiveFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : '') + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : '') + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn(_ref) {
        var search = _ref.search,
          searching = _ref.searching,
          loading = _ref.loading;
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('label', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t('agency.status')))]), _c('v-select', {
    staticClass: "w-100",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.statusAgencyOptions,
      "label": "label",
      "clearable": false,
      "value": _vm.statusAgencyFilter,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:statusAgencyFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn(_ref2) {
        var search = _ref2.search,
          searching = _ref2.searching,
          loading = _ref2.loading;
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('label', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t('agency.manager')))]), _c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.agenciesManagerList,
      "reduce": function reduce(val) {
        return val.id;
      },
      "clearable": false,
      "value": _vm.agencyManagerFilter,
      "searchable": "",
      "label": "id",
      "filterable": false,
      "loading": _vm.loading,
      "placeholder": _vm.$t('placeholderSelect')
    },
    on: {
      "open": _vm.openAgenciesManager,
      "search": _vm.searchAgenciesManager,
      "input": function input(val) {
        return _vm.$emit('update:agencyManagerFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref3) {
        var firstName = _ref3.firstName,
          lastName = _ref3.lastName;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")])])];
      }
    }, {
      key: "option",
      fn: function fn(_ref4) {
        var firstName = _ref4.firstName,
          lastName = _ref4.lastName;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(lastName) + " " + _vm._s(firstName) + " ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref5) {
        var loading = _ref5.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
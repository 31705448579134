<template>
  <div id="agencies-list">
    <!-- ANCHOR Filters -->
    <AgenciesListFilters
      :status-agency-filter.sync="statusAgencyFilter"
      :status-active-filter.sync="statusActiveFilter"
      :agency-manager-filter.sync="agencyManagerFilter"
      :status-agency-options="statusAgencyOptions"
      :status-active-options="statusActiveOptions"
      :empty-filter="isEmptyFilter"
      @fetch-data="refetchData"
      @reset="clearFilter"
    />

    <!-- Table Container Card -->
    <b-card
      style="max-height: 70vh"
      class=""
      no-body
    >
      <!-- SECTION Table Top -->
      <div class="m-2">
        <b-row>
          <!-- ANCHOR Per Page -->
          <b-col
            cols="4"
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sizePerPageLgOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
              <span class="text-muted ml-50">{{ $t('agency.agencies') }}</span>
            </v-select>
          </b-col>

          <!-- ANCHOR Button -->
          <b-col
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <div class="d-none d-lg-flex">
              <b-button
                class="mr-1"
                variant="info"
                :disabled="!canAccess('agency.createAgency')"
                @click="() => $router.push({ name: 'apps-agencies-add' })"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  {{ $t('agency.addAgency') }}
                </span>
              </b-button>

              <b-button
                variant="info"
                class=""
                :disabled="!canAccess('agency.exportAgency')"
                @click="confirmExport()"
              >
                <span class="text-nowrap">
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="cursor-pointer"
                  />
                  {{ $t('export') }}
                </span>
              </b-button>

              <!-- <b-button
                variant="danger"
                :disabled="activeDelete()"
                @click="confirmDelete()"
              >
                <span class="text-nowrap">
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="cursor-pointer"
                  />
                  {{ $t('delete') }}
                </span>
              </b-button> -->
            </div>
            <!-- Dropdown -->
            <div class="d-block d-lg-none">
              <b-dropdown
                variant="info"
                class="m-lg-2"
                boundary="window"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                  />
                  <span class="pl-50">{{ $t('agency.moreDropdown.title') }}</span>
                </template>
                <b-dropdown-item
                  :disabled="!canAccess('agency.createAgency')"
                  @click="() => $router.push({ name: 'apps-agencies-add' })"
                >
                  {{ $t('agency.moreDropdown.add') }}
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="!canAccess('agency.exportAgency')"
                  @click="confirmExport()"
                >
                  {{ $t('agency.moreDropdown.export') }}
                </b-dropdown-item>
                <!-- <b-dropdown-item
                  :disabled="activeDelete()"
                  @click="confirmDelete()"
                >
                  {{ $t('agency.moreDropdown.delete') }}
                </b-dropdown-item> -->
              </b-dropdown>
            </div>
          </b-col>

          <!-- ANCHOR Search -->
          <b-col
            cols="12"
            md="auto"
            class="mt-1 mt-md-0 px-50 flex-grow-1"
          >
            <div class="d-flex align-items-center">
              <b-input-group
                size="md"
                class="w-100 mr-md-1"
              >
                <template #prepend>
                  <div class="d-flex align-items-center border rounded-left px-1 bg-light-info">
                    <feather-icon
                      icon="SearchIcon"
                      size="20"
                    />
                  </div>
                </template>

                <b-form-input
                  v-model="searchTextFilter"
                  type="search"
                  :placeholder="$t('agency.placeholderSearch')"
                  :value="searchTextFilter"
                  :reduce="val => val.value"
                  :disabled="!canAccess('agency.searchAgency')"
                  debounce="500"
                  trim
                  @input="(val) => $emit('update:searchTextFilter', val)"
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- !SECTION -->

      <!-- SECTION Table content -->
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refAgenciesListTable"
          style="max-height: 50vh"
          :sticky-header="true"
          class="position-relative"
          :items="fetchAgencies"
          responsive
          :fields="tableColumnsAgency"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('noRecordFund')"
          :sort-desc.sync="isSortDirDesc"
          no-border-collapse
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumnsAgency"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`agency.${data.label}`) }}
            </span>
          </template>

          <!-- ANCHOR Checkbox -->
          <!-- <template #cell(checkbox)="data">
            <b-form-checkbox
              class="mr-0 mt-50"
              name="check-box"
              inline
              :checked="isChecked(data.item.id)"
              @change="chooseItem(data.item.id)"
            />
          </template> -->

          <!-- ANCHOR Information -->
          <template #cell(information)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="70"
                  :src="data.item.avatar"
                  :text="avatarText(`${data.item.agencyName}`)"
                  variant="light-primary"
                />
                <!-- :variant="`light-${resolveUserRoleVariant(data.item.type)}`" -->
                <!-- :to="{name: 'apps-agencies-edit', params: { id: data.item.id }}" -->
              </template>
              <h5>
                <span class="font-weight-bold d-block text-nowrap">
                  <!-- :to="{name: 'apps-agencies-edit', params: { id: data.item.id }}" -->
                  {{ data.item.agencyName }}
                </span>
              </h5>
              <small class="d-flex align-items-center">
                <feather-icon
                  size="14"
                  icon="TagIcon"
                  class="mr-50"
                />
                <!-- <span class="font-weight-bold">{{ data.item.holderName || 'No information' }} </span> -->
                <span>{{ data.item.agencyOwner.name || 'N/A' }} </span>
              </small>
              <small class="d-flex align-items-center">
                <feather-icon
                  size="14"
                  icon="MailIcon"
                  class="mr-50"
                />
                <span>{{ data.item.emailAddress || 'N/A' }}</span>
              </small>
            </b-media>
          </template>

          <!-- ANCHOR Agency Code -->
          <template #cell(balance)="{ item: { creditInfo } }">
            <div
              :class="(creditInfo.isOverLimit || creditInfo.isWarningLimitPercent) ? 'text-danger' : creditInfo.isWarning ? 'text-warning' : 'text-success'"
              style="white-space: nowrap;"
            >
              <span>Công nợ:</span> {{ formatCurrency(creditInfo.balance) }}
            </div>
            <div class="text-nowrap">
              <span>Hạn mức:</span> {{ formatCurrency(creditInfo.limitCredit) }}
            </div>
          </template>

          <!-- ANCHOR Agency Code -->
          <template #cell(agencyCode)="data">
            <b-link
              :disabled="!canAccess('agency.detailAgency')"
              :to="{ name: 'apps-agencies-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap text-info pb-0"
            >
              {{ data.item.agencyCode.toUpperCase() }}
            </b-link>
          </template>

          <!-- ANCHOR Maganer -->
          <template #cell(manager)="data">
            <p
              v-if="data.item.agencyManager"
              class="text-nowrap mb-0"
            >
              {{ `${data.item.agencyManager.lastName} ${data.item.agencyManager.firstName}` }}
            </p>
          </template>

          <!-- ANCHOR createdAt -->
          <template #cell(createdAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.createdAt) }}
                </h6>
                <small v-if="data.item.createdBy">
                  {{ `${data.item.createdBy.firstName} ${data.item.createdBy.lastName}` }}
                </small>
              </b-media-body>
            </b-media>
          </template>

          <!-- ANCHOR updatedAt -->
          <template #cell(updatedAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.updatedAt) }}
                </h6>
                <small v-if="data.item.updatedBy">
                  {{ `${data.item.updatedBy.firstName} ${data.item.updatedBy.lastName}` }}
                </small>
              </b-media-body>
            </b-media>
          </template>

          <!-- ANCHOR Operation status / isActive -->
          <template #cell(isActive)="data">
            <div class="w-100 d-flex-center">
              <b-form-checkbox
                v-if="canAccess('agency.updateAgency') && data.item.id !== agencyDataAgId"
                v-model="data.item.isActive"
                class="mr-0 mt-50 custom-control-success"
                name="check-button"
                switch
                inline
                @click.native.prevent="changeActive(data.item)"
              />
              <b-form-checkbox
                v-else
                :checked="data.item.isActive"
                class="mr-0 mt-50 custom-control-success"
                name="check-button"
                switch
                inline
                disabled
              />
            </div>
          </template>

          <!-- ANCHOR contract status -->
          <template #cell(status)="data">
            <div class="w-100 d-flex-center">
              <b-badge
                variant="info"
                class="badge-glow text-uppercase"
              >
                {{ $t(resolveAgencyStatus(data.item.status, 'value', 'label')) }}
              </b-badge>
            </div>
          </template>

          <!-- ANCHOR retail -->
          <template #cell(retail)="data">
            <div class="w-100 d-flex-center gap-2">
              <div class="w-100 d-flex-center">
                <b-form-checkbox
                  v-model="data.item.enableRetail"
                  class="mr-0 custom-control-success"
                  name="check-button"
                  switch
                  inline
                  @click.native.prevent="changeEnableRetail(data.item)"
                />
              </div>

              <BButton
                v-if="data.item"
                variant="outline-info"
                class="p-50"
                @click="giftButtonHandle(data.item)"
              >
                <div class="d-flex-center gap-1 flex-nowrap">
                  <feather-icon
                    icon="GiftIcon"
                    size="16"
                  />
                  <span class="text-nowrap">{{ $t('packageConfig.agencyPackageConfigsGift') }}</span>
                </div>
              </BButton>
            </div>
          </template>

          <!-- ANCHOR Agency fee for f2 -->
          <template #cell(agencyFee)="data">
            <b-button
              v-if="isRoleF2 || (isRoleF1 && isType)"
              :variant="data.item.applyAgencyFee ? 'outline-warning' : 'outline-info'"
              class="p-50 d-flex-center"
              style="min-width: 110px;"
              size="sm"
              @click="openModalUpdateAgencyFee(data.item)"
            >
              {{ data.item.applyAgencyFee ? 'Thay đổi phí xuất vé riêng' : 'Áp dụng phí xuất vé riêng' }}
            </b-button>
            <b-button
              v-else-if="isRoleF1 && !isType"
              :variant="data.item.applyAgencyFee ? 'outline-warning' : 'outline-info'"
              class="p-50 d-flex-center"
              style="min-width: 110px;"
              size="sm"
              :disabled="!data.item.applyAgencyFee"
              @click="openModalUpdateAgencyFee(data.item)"
            >
              {{ data.item.applyAgencyFee ? 'Đã cài phí xuất vé riêng' : 'Chưa cài phí xuất vé riêng' }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>
      <!-- !SECTION -->

      <!-- ANCHOR: Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAgencies"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <ModalUpdateAgencyFee
      :agencies-data="agenciesDataToUpdateAgencyFee"
      @refetchAgency="refetchData"
    />
    <ModalBuy
      :agency-item="agencyItem"
      :is-gift="true"
      :disabled-agency-item="true"
    />
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BBadge,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaBody,
  BMediaAside,
  BPagination,
  BInputGroup,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref, computed } from '@vue/composition-api'

import store from '@/store'
import {
  statusAgencyOptions, statusOptions as statusActiveOptions, sizePerPageLgOptions, resolveAgencyStatus,
} from '@/constants/selectOptions'

import { avatarText, dateTime, formatCurrency } from '@core/utils/filter'

import useAgenciesHandle from '@agencies/useAgenciesHandle'
import agenciesStoreModule from '@agencies/agenciesStoreModule'

import AgenciesListFilters from './AgenciesListFilters.vue'

export default {
  components: {
    AgenciesListFilters,
    BMediaBody,
    BBadge,
    BInputGroup,
    BMediaAside,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BOverlay,
    BDropdown,
    BDropdownItem,
    vSelect,
    ModalUpdateAgencyFee: () => import('@agencies/agencies-edit/ModalUpdateAgencyFee.vue'),
    ModalBuy: () => import('@/views/retail-package/components/ModalBuy.vue'),

  },
  setup(_, { root }) {
    const AGENCIES_APP_STORE_MODULE_NAME = 'app-agencies'
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const meData = computed(() => store.getters['userStore/getMeData'])
    const isType = computed(() => ['OPE', 'ADM'].includes(meData.value?.type))

    // Register module
    if (!store.hasModule(AGENCIES_APP_STORE_MODULE_NAME)) {
      store.registerModule(AGENCIES_APP_STORE_MODULE_NAME, agenciesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AGENCIES_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(AGENCIES_APP_STORE_MODULE_NAME)
      }
    })

    const {
      bulkDeleteAgencies,
      tableColumns,
      sizePerPage,
      currentPage,
      totalAgencies,
      idsPage,
      dataMeta,
      perPageOptions,

      // Refs
      refAgenciesListTable,

      // Methods
      refetchData,
      clearFilter,

      // Dispatch store
      fetchAgencies,
      updateActive,
      updateDeactive,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      searchTextFilter,
      statusActiveFilter,
      statusAgencyFilter,
      agencyManagerFilter,

      isEmptyFilter,

      // Sort
      sortBy,
      isSortDirDesc,

      // Loading
      loading,
      exportAgencies,
      updateEnableRetail,
    } = useAgenciesHandle()
    const isAll = ref(false)
    const ignoreIds = ref([])
    const deleteIds = ref([])

    function confirmDelete() {
      // show modal confirm delete employee
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmDelete') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            bulkDeleteAgencies(deleteIds.value)
            deleteIds.value = []
          }
        })
    }

    function confirmExport() {
      // show modal confirm export employee
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.exportAgencies(this.$i18n.locale)
            // deleteIds = []
          }
        })
    }

    function changeActive(agency) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatus'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            if (agency.isActive) {
              this.updateDeactive(agency.id)
            } else {
              this.updateActive(agency.id)
            }
          }
        })
    }

    function changeEnableRetail(agency) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: root.$t('agency.retail') }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            await updateEnableRetail(agency.id)
          }
        })
    }

    function setArray(array, id) {
      const index = array ? array.findIndex(element => element === id) : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? array.splice(index, 1) : array.push(id)

      return array
    }
    function chooseItem(id) {
      deleteIds.value = setArray(deleteIds.value, id)
    }

    function isChecked(id) {
      return (
        deleteIds.value.includes(id)
      )
    }

    // function isCheckedAll() {
    //   if (this.dataMeta.of > 0) {
    //     return (
    //       deleteIds.value.length === this.idsPage.length
    //     )
    //   }
    //   return false
    // }

    // function checkAll() {
    //   if (this.ignoreIds.length > 0) {
    //     deleteIds.value = this.idsPage
    //   } else {
    //     this.isAll = !this.isAll
    //     if (!this.isAll) {
    //       deleteIds.value = []
    //     }
    //   }
    // }

    function activeDelete() {
      return deleteIds.value.length === 0
    }

    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const agencyDataAgId = computed(() => agencyData.value?.id)

    // eslint-disable-next-line arrow-body-style
    const tableColumnsAgency = computed(() => {
      // if (isRoleF1.value && isType.value) {
      //   return tableColumns.filter(item => item.key !== 'agencyFee')
      // }
      return tableColumns.filter(item => item.key !== 'Retail')
    })

    const agenciesDataToUpdateAgencyFee = ref(null)
    function openModalUpdateAgencyFee(agency) {
      agenciesDataToUpdateAgencyFee.value = agency
      this.$nextTick(() => this.$bvModal.show('modal-update-agency-fee'))
    }

    const agencyItem = ref()
    async function giftButtonHandle(agency) {
      agencyItem.value = agency
      this.$bvModal.show('modal-buy-package-config')
    }

    return {
      meData,
      isType,
      isAll,
      ignoreIds,
      deleteIds,
      idsPage,
      changeActive,
      chooseItem,
      isChecked,
      // isCheckedAll,
      // checkAll,
      activeDelete,

      fetchAgencies,
      bulkDeleteAgencies,
      updateActive,
      updateDeactive,
      setArray,
      confirmDelete,
      dateTime,
      tableColumnsAgency,
      sizePerPage,
      currentPage,
      totalAgencies,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refAgenciesListTable,
      refetchData,

      // Filter
      avatarText,
      isEmptyFilter,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // select Options
      statusAgencyOptions,
      statusActiveOptions,
      sizePerPageLgOptions,
      resolveAgencyStatus,

      // Extra Filters
      statusActiveFilter,
      statusAgencyFilter,
      agencyManagerFilter,
      searchTextFilter,
      clearFilter,

      loading,
      exportAgencies,
      confirmExport,
      formatCurrency,
      agencyDataAgId,

      agenciesDataToUpdateAgencyFee,
      openModalUpdateAgencyFee,
      giftButtonHandle,
      agencyItem,
      changeEnableRetail,
      isRoleF1,
      isRoleF2,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
// Switch color
// #agencies-list ::v-deep {
//  .custom-switch .custom-control-input:not(:checked)~.custom-control-label:before {
//    background-color: #ea5455; // same danger color of bootstrap
//  }
// }
</style>
